import React from 'react';
import { Text, Link } from '@sitecore-jss/sitecore-jss-react';
import TabbedContentVariant from './TabbedContentVariant';
import { DefaultProps, PropTypes } from '../../../core/Utils/SitecoreFields';

class HelpMeFindVariant extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: null,
      savedTabIndex: -1,
      active: false,
    };
  }

  selectDropdown = (tab, index) => {
    this.setState(
      {
        activeTab: null,
      },
      () =>
        this.setState({
          activeTab: tab,
        }),
    );

    this.setState((prevState) => {
      if (prevState.savedTabIndex !== index || prevState.savedTabIndex === -1) {
        return {
          savedTabIndex: index,
          active: true,
        };
      }
      return {
        savedTabIndex: -1,
        active: false,
      };
    });
  };

  render() {
    const { title, tabList } = this.props.fields;
    const { activeTab, savedTabIndex, active } = this.state;
    const onlyLinks = tabList.some((item) => {
      const onlylinkfield =
        item.fields.link != null &&
        item.fields.link.value &&
        item.fields.link.value.href;
      return onlylinkfield;
    });
    return (
      <div
        className={`tab-dropdown-container help-me-find help-me-find-variant ${
          onlyLinks ? 'help-me-find-links' : 'help-me-find-tabs'
        }`}
      >
        <div className="l-padding">
          <div className="animated pulse">
            <div className="tab-dropdown">
              <div className="dropdown-header  ">
                <Text field={title} tag="h2" />
              </div>
              <div className="dropdown-body">
                <div className="lto3-ddb">
                  <ul>
                    {tabList.map((item, index) => {
                      const dropdownbody = (
                        <li key={item.fields.heading.value}>
                          {item.fields.link != null &&
                          item.fields.link.value != null &&
                          item.fields.link.value.href ? (
                            <Link
                              field={item.fields.link.value}
                              className="dropdown-list-button"
                            >
                              {item.fields.heading.value}
                            </Link>
                          ) : (
                            <button
                              className={`dropdown-list-button ${
                                savedTabIndex === index && active
                                  ? 'lto3selected'
                                  : ''
                              }`}
                              type="button"
                              aria-label={item.fields.heading.value}
                              index={index}
                              onClick={() => this.selectDropdown(item, index)}
                            >
                              <span>{item.fields.heading.value}</span>
                            </button>
                          )}
                        </li>
                      );
                      return dropdownbody;
                    })}
                  </ul>
                </div>
              </div>
              {activeTab && (
                <TabbedContentVariant
                  fields={{
                    ...activeTab.fields,
                    heading: {},
                  }}
                  savedTab={savedTabIndex}
                  handleActiveIndex={(index) =>
                    this.setState({ savedTabIndex: index })
                  }
                  isActiveTab={active}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

HelpMeFindVariant.propTypes = PropTypes.SitecoreItem.inject({
  title: PropTypes.SingleLineText,
  placeholder: PropTypes.SingleLineText,
  ariaLabel: PropTypes.SingleLineText,
  tabList: PropTypes.Multilist.inject({
    heading: PropTypes.SingleLineText,
    canHide: PropTypes.Checkbox,
    tabItems: PropTypes.Multilist.inject({
      title: PropTypes.SingleLineText,
      description: PropTypes.RichText,
      links: PropTypes.Multilist,
    }),
    hideText: PropTypes.SingleLineText,
    link: PropTypes.link,
  }),
});

HelpMeFindVariant.defaultProps = DefaultProps.SitecoreItem.inject({
  title: DefaultProps.SingleLineText,
  placeholder: DefaultProps.SingleLineText,
  tabList: DefaultProps.Multilist,
  ariaLabel: DefaultProps.SingleLineText,
});

export default HelpMeFindVariant;
