import React from 'react';
import { Text, RichText, Link } from '@sitecore-jss/sitecore-jss-react';
import { DefaultProps, PropTypes } from '../../../core/Utils/SitecoreFields';

class TabbedContentVariant extends React.Component {
  constructor(props) {
    super(props);
    const { fields, savedTab, isActiveTab } = this.props;
    const { tabItems } = fields;

    const savedIndex = savedTab > tabItems.length ? 0 : savedTab;

    this.state = {
      tabArray: tabItems.map((item, index) => {
        const indexValue = {
          isActive: index === savedIndex,
          fields: item.fields,
        };
        return indexValue;
      }),
      isActiveTab,
    };
  }

  render() {
    const { tabArray, isActiveTab } = this.state;
    return (
      <div
        className={`react-tabs lto3tab4 lto3tabs ${
          isActiveTab ? 'active' : ''
        }`}
        data-tabs="true"
      >
        <div
          className="react-tabs__tab-panel react-tabs__tab-panel--selected lto3in1"
          role="tabpanel"
          aria-labelledby="react-tabs"
        >
          <div className="tab-body-content">
            <Text field={tabArray[0].fields.title} tag="h3" />
            <RichText field={tabArray[0].fields.description} tag="p" />
          </div>
          <div className="tab-links">
            <ul>
              {tabArray[0].fields.links.map((itemlinks) => {
                const tabLinks = (
                  <li key={itemlinks.fields.link}>
                    {itemlinks.fields.link.value.href ? (
                      <Link
                        field={itemlinks.fields.link.value}
                        className="link"
                      />
                    ) : (
                      ''
                    )}
                  </li>
                );
                return tabLinks;
              })}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

TabbedContentVariant.propTypes = PropTypes.SitecoreItem.inject({
  heading: PropTypes.SingleLineText,
  tabItems: PropTypes.Multilist.inject({
    title: PropTypes.SingleLineText,
    description: PropTypes.RichText,
    links: PropTypes.Multilist,
  }),
  hideText: PropTypes.SingleLineText,
});

TabbedContentVariant.defaultProps = DefaultProps.SitecoreItem.inject({
  heading: DefaultProps.SingleLineText,
  tabItems: DefaultProps.Multilist,
  hideText: DefaultProps.SingleLineText,
});
export default TabbedContentVariant;
